import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const Header = ({ children, ...props }) => (
  <chakra.header {...props}>{children}</chakra.header>
)

Header.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
}

export default Header
