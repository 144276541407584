import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const Nav = ({ children, ...props }) => (
  <chakra.nav {...props}>{children}</chakra.nav>
)

Nav.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
}

export default Nav
