import React from "react"
import { Link as LinkGatsby, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import {
  chakra,
  Container,
  Heading,
  Text,
  Flex,
  Box,
  Link,
  Img,
} from "@chakra-ui/react"
import { Article, Nav, Header, Section } from "../componentsWithChakra"
import { CgArrowLeft, CgArrowRight, CgCornerUpLeft } from "react-icons/cg"
import { GoPrimitiveSquare } from "react-icons/go"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const TemplateConcepts = (props) => {
  const slug = props.path
  const concepts = props.data.mdx
  const siteTitle = props.data.site.siteMetadata.title
  const { previous, next } = props.pageContext

  let anchor = props.location.pathname
    .replace(/\/concepts\//g, "")
    .replace(/\//g, "")

  return (
    <Layout>
      <SEO
        title={concepts.frontmatter.title + " | THE PROJECT STATUS"}
        urlPage={slug}
        description={concepts.frontmatter.description}
      />
      <Container maxW="800px" mt={8} px={4}>
        <Nav>
          <Link as={LinkGatsby} to={"/concepts#" + anchor}>
            <Flex align="center" color="grey.600">
              <Box mr={2}>
                <CgCornerUpLeft />
              </Box>
              back to Concepts
            </Flex>
          </Link>
        </Nav>
        <Flex direction="column" justify="space-between" minH="640px">
          <Article>
            <Header>
              <Box as="h1" textStyle="h2" mt={8}>
                {concepts.frontmatter.title}
              </Box>
              <Flex mb={4} fontSize="sm" color="grey.600">
                <Text mr={2}>{concepts.frontmatter.version}</Text>
                {"·"}
                <Text ml={2}>{concepts.frontmatter.author}</Text>
              </Flex>
            </Header>
            <Img
              as={GatsbyImage}
              load="eager"
              image={concepts.frontmatter.image.childImageSharp.gatsbyImageData}
              mb={8}
              border={`${concepts.frontmatter.border}px solid #999`}
            />
            <Section>
              <MDXRenderer>{concepts.body}</MDXRenderer>
            </Section>
          </Article>

          <Nav color="grey.600" mt={12}>
            <Link as={LinkGatsby} to={"/concepts#" + anchor}>
              <Flex align="center">
                <Box mr={2}>
                  <CgCornerUpLeft />
                </Box>
                back to Concepts
              </Flex>
            </Link>
            <Flex justify="space-between" align="center">
              <Box>
                {previous ? (
                  <Link as={LinkGatsby} to={previous.fields.slug} rel="prev">
                    <Flex align="center">
                      <Box mr={2}>
                        <CgArrowLeft />
                      </Box>
                      {previous.frontmatter.title}
                    </Flex>
                  </Link>
                ) : (
                  <Box>
                    <GoPrimitiveSquare />
                  </Box>
                )}
              </Box>
              <Box>
                {next ? (
                  <Link as={LinkGatsby} to={next.fields.slug} rel="next">
                    <Flex align="center">
                      {next.frontmatter.title}
                      <Box ml={2}>
                        <CgArrowRight />
                      </Box>
                    </Flex>
                  </Link>
                ) : (
                  <Box>
                    <GoPrimitiveSquare />
                  </Box>
                )}
              </Box>
            </Flex>
          </Nav>
        </Flex>
      </Container>
    </Layout>
  )
}

export default TemplateConcepts

export const pageQuery = graphql`
  query ConceptsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        dateFormatted: date(formatString: "yyyy-MM-DD")
        version
        description
        author
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, layout: CONSTRAINED)
          }
        }
        border
      }
    }
  }
`
