import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const Article = ({ children, ...rest }) => (
  <chakra.article {...rest}>{children}</chakra.article>
)

Article.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
}

export default Article
