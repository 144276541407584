import { chakra } from "@chakra-ui/react"

const LabelTop = chakra("label", {
  // attach style props
  baseStyle: {
    fontSize: "sm",
    color: "grey.600",
    fontWeight: "normal",
    display: "inline-block",
  },
})

export default LabelTop
