import React from "react"
import PropTypes from "prop-types"
import { chakra } from "@chakra-ui/react"

const Section = ({ children, ...props }) => (
  <chakra.section {...props}>{children}</chakra.section>
)

Section.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
}
export default Section
